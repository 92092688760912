table tbody td {
	width: 10%;
}
.sku {
	color: #0c697e;
	font-weight: bold;
}
.modal-wrapper {
	width: 100% !important;
}

.order-card {
	box-shadow: none !important;
}

.card-header {
	display: flex;
	align-items: center;
	border-bottom: 1px lightGray solid;
}

.card-content {
	padding-top: 30px !important;
	cursor: pointer;
	box-shadow: none !important;
}
