.ion-page {
  // max-width: 900px;
  margin: 0 auto !important;
  overflow-y: scroll;
}

.accordion__item:focus {
  outline-color: white !important;
}
.accordion__heading:focus {
  outline-color: white !important;
}

.accordion__button:focus {
  outline-color: #ffffff !important;
}

.hint-modal {
  position: fixed;
  bottom: 0;
  .modal-wrapper,
  .modal-shadow {
    height: 70%;
    bottom: 0;
    padding: 10%;
    position: fixed;
    // display: block;
    border-radius: 20px 20px 0 0;
  }
}

//fetch on scroll component
.fetch-on-scroll-data-wrapper {
  position: relative;

  & * {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
}

.fetch-on-scroll-loading-data {
  background-color: #a5a1a1;
}

.fetch-on-scroll-data {
  max-height: 730px;
  overflow-y: scroll; /* has to be scroll, not auto */
  -webkit-overflow-scrolling: touch;
}

.searchbar-input {
  border-radius: 25px !important;
  font-size: 14px !important;
}

// .backdrop-no-scroll {
//   overflow: hidden !important;
// }

.search-form {
  display: none;
  width: 100%;
  max-width: 295px;
  padding-left: 10px;
}

.search-form .ion-search-bar {
  padding: 0;
}

.fav-flowers-title {
  text-align: left;
  color: #dc143c;
  font-weight: bold;
  margin-bottom: 15px;
  text-decoration: underline;
}

@media (min-width: 500px) {
  .search-form {
    display: inline-block;
  }
}

.in-item {
  max-width: 70% !important;
  padding-bottom: 7px !important;
  font-size: 15px !important;
}

.sc-ion-label-md-h {
  font-size: 14px !important;
}

.popover-content {
  top: 60px !important;
  left: unset !important;
  right: 85px;
}

.notification-popover {
  .list-md {
    padding: 0 !important;
  }

  // .hydrated {
  // 	background-color: #999;
  // }
}

.notifications-list-header {
  font-weight: bold;
  color: #0c697e;
  border: 1px solid #eee;
}

.notification-badge-wrapper {
  font-size: 15px;
  color: white;
  margin-top: 6px;
  padding: 0;
  border-radius: 0;
  background-color: transparent;
  display: block;
  visibility: visible;
  min-width: unset;
  white-space: unset;
  contain: unset;
  font-family: "Roboto","Helvetica Neue",sans-serif;
  -webkit-font-smoothing: antialiased;
  font-size: 15px;
  overflow-wrap: break-word;
  text-rendering: optimizeLegibility;
  font-weight: 400;
  line-height: 1.2;
  .notification-badge {
    width: 18px;
    height: 18px;
    background-color: red;
    border-radius: 10px;
    margin-right: -10px;
    cursor: pointer;
  }
}

.nw-order {
  background-color: #022069;
  color: white;
  display: inline-block;
  padding: 2px 6px;
  text-align: center;
  font-size: 14px;
  align-self: center;
  margin-top: 3px;
  border-radius: 3px;
  align-self: baseline;
  margin-left: 8px;
}

.select-full-width {
  max-width: 100% !important;
  width: 100%;
  padding-left: 0;
}
