.assign-florist {
  display: flex;
  flex-direction: column;
  justify-content: center;
  h6 {
    margin: 0;
  }
}

.vip-order {
  background-color: black;
  color: white;
  display: inline-block;
  padding: 3px;
  text-align: center;
  font-size: 14px;
  align-self: center;
  margin-top: 3px;
  border-radius: 3px;
  align-self: end;
  margin-left: 17px;
}
