.login-wrapper {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  padding: 80px 10px 0 10px;

  .login-title {
    font-size: 26px;
    line-height: 30px;
    color: #222b45;
    text-align: center;
  }

  .input-wrapper {
    border: 1px solid #2aa5c1;
    border-radius: 6px;
    padding-top: 0;
		padding-bottom: 0;
    --border-style: none !important;
  }
  .select-gender-style {
    color: black;
    padding: 0 16px !important;
    height: 45px !important;
    width: 100%;
    border: 1px solid #2aa5c1;
    border-radius: 6px;
    background-color: white;
    font-size: 14px;
  }

  .login-btn {
    width: 100%;
    border-radius: 6px;
    height: 45px;
  }

	.login-btn-microsoft {
		@extend .login-btn;
		background-color: #fdfdfd;
		color: black;

		&:hover {
			background-color: #0c697e;
			color: #fff;
		}
	}

	.or {
		text-align: center;
	}

	.ms-icon {
		width: 20px;
		margin-right: 10px;
	}
}

#ion-input-0-lbl {
  color: #c5cee0;
  font-size: 14px;
}

#ion-input-1-lbl {
  color: #c5cee0;
  font-size: 14px;
}
#ion-input-2-lbl {
  color: #c5cee0;
  font-size: 14px;
}

.item-label-floating {
  --min-height: 42px !important;
}
