.home-icons {
  color: #0c697e;
  box-shadow: 0 3px 16px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.01);
  border-radius: 5px;
  width: 25px;
  height: 25px;
  cursor: pointer;
}

.completed-orders {
  color: #0c697e;
  display: inline-block;
  border: 1px #0c697e solid;
  cursor: pointer;
  padding: 1px;
}
